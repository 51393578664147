import * as React from "react";
import { Card, CardContent, Typography, Box } from "@mui/material";

interface StatCardInput {
  action: string;
  heading: string;
  value: number;
  onClick: (action: string) => void;
}

export default function StatCard({
  action,
  heading,
  value,
  onClick
}: StatCardInput) {
  return (
    <Card
      elevation={3}
      sx={{
        display: "flex",
        minWidth: 240,
        minHeight: 240,
        borderRadius: 2,
        cursor: "pointer",
        "&:hover": { boxShadow: 6 }
      }}
      onClick={() => onClick(action)}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          flex: 1,
          userSelect: "none"
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center"
          }}
        >
          <Typography
            gutterBottom
            sx={{
              color: "text.secondary",
              fontSize: 24,
              wordWrap: "break-word"
            }}
          >
            {heading}
          </Typography>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-end"
          }}
        >
          <Typography
            color="info"
            sx={{
              fontSize: 60,
              textAlign: "center",
              whiteSpace: "nowrap"
            }}
          >
            {value}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
}
