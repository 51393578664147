import React from "react";
import { Navigate } from "react-router-dom";
import { jwtDecode } from "jwt-decode";

const checkTokenExpiration = (token: string) => {
  const decoded: any = jwtDecode(token);
  const currentTime = Date.now() / 1000 - 3600;

  if (decoded.iat && decoded.iat < currentTime) {
    localStorage.removeItem("token");
    return false;
  } else {
    return true;
  }
};

const ProtectedRoute: React.FC<{ children: React.ReactNode }> = ({
  children
}) => {
  const token = localStorage.getItem("token");

  return token && checkTokenExpiration(token) ? (
    <>{children}</>
  ) : (
    <Navigate to="/" />
  );
};

export default ProtectedRoute;
