import { useEffect, useState } from "react";

/**
 * Custom hook to debounce a value after a specified delay.
 *
 * @param {T} value - The value to debounce.
 * @param {number} delay - The delay in milliseconds for debouncing.
 * @returns {T} The debounced value.
 *
 * @example
 * const debouncedValue = useDebounce(inputValue, 500);
 */
export function useDebounce<T>(value: T, delay: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    // Cleanup the timeout if value or delay changes
    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}
