import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, TextField, Container, Typography, Alert } from "@mui/material";
import { login } from "../api";

const Login: React.FC = () => {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showError, setShowError] = useState(false);
  const [loginError, setLoginError] = useState("Invalid username/password");
  const navigate = useNavigate();

  const handleSubmit = async () => {
    setShowError(false);
    try {
      const res = await login(username, password);
      const { data } = res;
      if (data?.token) {
        localStorage.setItem("token", data.token);
        navigate("/home");
      } else {
        if (data.strMessage) {
          if (data.strMessage === "Unauthorized") {
            setLoginError("Invalid username or password");
          } else {
            setLoginError(data.strMessage);
          }
        }
        setShowError(true);
      }
    } catch (error) {
      if (error instanceof Error) {
        setLoginError(error.message);
      } else {
        setLoginError("An unknown error has occured.");
      }

      setShowError(true);
    }
  };

  return (
    <Container maxWidth="sm">
      <Typography variant="h2" align="center" mt={8} gutterBottom>
        Login
      </Typography>
      <Container style={{ display: "grid", justifyItems: "center" }}>
        {showError ? (
          <Alert
            // style={{ width: "-webkit-fill-available" }}
            variant="outlined"
            severity="error"
          >
            {loginError}
          </Alert>
        ) : (
          <></>
        )}

        <TextField
          style={{ marginTop: "36px" }}
          label="Username"
          fullWidth
          value={username}
          autoComplete="username"
          onChange={(e) => setUsername(e.target.value)}
        />
        <TextField
          style={{ marginTop: "16px" }}
          label="Password"
          type="password"
          autoComplete="password"
          fullWidth
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Button
          style={{ marginTop: "24px", width: "50%", alignSelf: "end" }}
          variant="contained"
          color="primary"
          onClick={handleSubmit}
          fullWidth
        >
          Log In
        </Button>
      </Container>
    </Container>
  );
};

export default Login;
