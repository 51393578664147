import React, { Fragment, useState } from "react";
import Layout from "../components/Layout";
import {
  Alert,
  CircularProgress,
  Fade,
  Grid2,
  Typography
} from "@mui/material";
import { fetchStats } from "../api";
import StatCard from "../components/StatCard";
import { useNavigate } from "react-router-dom";

export interface SnapperStats {
  intDomainsDeleting1: number;
  intDomainsDeleting14: number;
  intDomainsAutorenewOff: number;
  intDomainsTotal: number;
  intDomainsGrab: number;
  intDomainsGrabbed: number;
}

const STATS_MAP = {
  intDomainsDeleting1: "Domains Deleting In 24 Hours",
  intDomainsDeleting14: "Domains Deleting In 2 Weeks",
  intDomainsAutorenewOff: "Domains With Autorenew Disabled",
  intDomainsTotal: "Total Domains",
  intDomainsGrab: "Total Domains Being Snapped",
  intDomainsGrabbed: "Total Domains Successfully Snapped"
};

const Home: React.FC = () => {
  const navigate = useNavigate();

  const [stats, setStats] = useState<SnapperStats | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const handleClick = (action: string) => {
    navigate(`/list?action=${action}`);
  };

  React.useEffect(() => {
    setIsLoading(true);
    fetchStats()
      .then((data) => {
        setStats(data.stats);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Layout>
      <Typography variant="h5" mt={2}>
        Home
      </Typography>
      {error ? <Alert severity="error">{error}</Alert> : <></>}
      {isLoading && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            scale: "200%",
            zIndex: 1
          }}
        >
          <CircularProgress color="info" />
        </div>
      )}
      <Fade in={!isLoading} timeout={300}>
        <Grid2 container spacing={2} p={8}>
          {stats &&
            Object.keys(stats).map((key) => {
              return (
                <Grid2
                  size={{ sm: 12, md: 6, lg: 4, xl: 3 }}
                  key={`grid-${key}`}
                >
                  <StatCard
                    key={key}
                    action={key}
                    onClick={handleClick}
                    heading={STATS_MAP[key as keyof SnapperStats]}
                    value={stats[key as keyof SnapperStats] ?? 0}
                  />
                </Grid2>
              );
            })}
        </Grid2>
      </Fade>
    </Layout>
  );
};

export default Home;
