import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  IconButton,
  Divider,
  Tooltip
} from "@mui/material";
import { Close, Refresh } from "@mui/icons-material";

const FilterPopup = ({ onApply, open, setClose }: any) => {
  const [autorenew, setAutorenew] = useState("any");
  const [snap, setSnap] = useState("any");
  const [snapped, setSnapped] = useState("any");
  const [registrar, setRegistrar] = useState("");
  const [daysUntilDeletion, setDaysUntilDeletion] = useState<
    number | undefined
  >(undefined);

  const handleApply = () => {
    onApply({ autorenew, registrar, daysUntilDeletion, snap, snapped });
    setClose();
  };

  return (
    <>
      <Dialog open={open} onClose={setClose}>
        <DialogTitle>Filter Options</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={setClose}
          sx={(theme) => ({
            position: "absolute",
            right: 8,
            top: 8,
            color: theme.palette.grey[500]
          })}
        >
          <Close />
        </IconButton>
        <Divider />
        <DialogContent>
          {/* Autorenew Select */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Autorenew</InputLabel>
            <Select
              value={autorenew}
              onChange={(e) => setAutorenew(e.target.value)}
              label="Autorenew"
            >
              <MenuItem value="any">Any</MenuItem>
              <MenuItem value="enabled">Enabled</MenuItem>
              <MenuItem value="disabled">Disabled</MenuItem>
            </Select>
          </FormControl>
          {/* Snap Select */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Snap</InputLabel>
            <Select
              value={snap}
              onChange={(e) => setSnap(e.target.value)}
              label="Snap"
            >
              <MenuItem value="any">Any</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          {/* Snapped Select */}
          <FormControl fullWidth margin="normal">
            <InputLabel>Snapped</InputLabel>
            <Select
              value={snapped}
              onChange={(e) => setSnapped(e.target.value)}
              label="Snapped"
            >
              <MenuItem value="any">Any</MenuItem>
              <MenuItem value="yes">Yes</MenuItem>
              <MenuItem value="no">No</MenuItem>
            </Select>
          </FormControl>
          {/* Registrar Input */}
          <TextField
            label="Registrar"
            variant="outlined"
            fullWidth
            value={registrar}
            onChange={(e) => setRegistrar(e.target.value)}
            margin="normal"
          />
          {/* Deleting in X Days Number Picker */}
          <TextField
            label={`Deleting in ${
              daysUntilDeletion !== undefined ? daysUntilDeletion : "X"
            } Days`}
            type="number"
            fullWidth
            value={daysUntilDeletion ?? ""}
            onChange={(e) => setDaysUntilDeletion(Number(e.target.value))}
            margin="normal"
            InputProps={{ inputProps: { min: 0, max: 365 } }}
          />
          <Tooltip title="Reset filters">
            <IconButton
              onClick={() => {
                setDaysUntilDeletion(undefined);
                setRegistrar("");
                setAutorenew("any");
                setSnap("any");
                setSnapped("any");
                onApply({
                  autorenew: "any",
                  snap: "any",
                  snapped: "any",
                  registrar: "",
                  daysUntilDeletion: undefined
                });
              }}
            >
              <Refresh />
            </IconButton>
          </Tooltip>
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ mr: 2, mb: 2 }}
            onClick={handleApply}
            color="primary"
            variant="contained"
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default FilterPopup;
