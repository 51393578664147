import React, { useEffect, useState } from "react";
import Layout from "../components/Layout";
import {
  autorenewToggle,
  bulkGrab,
  cancelGrab,
  deleteDomain,
  fetchDomains,
  grab
} from "../api";
import DataTable, { Order, SnapperDomain } from "../components/DataTable";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid2,
  IconButton,
  InputAdornment,
  List,
  ListItem,
  ListItemText,
  Snackbar,
  TextField,
  Typography
} from "@mui/material";
import { Add, Clear, Close, FileDownload } from "@mui/icons-material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useDebounce } from "../hooks/useDebounce";
import { useLocation } from "react-router-dom";

export interface SearchFilters {
  search?: string;
  startPoint?: number;
  limit?: number;
  sortBy?: keyof SnapperDomain;
  order?: "asc" | "desc";
  strRegistrar?: string;
  expiringIn?: number;
  deletingIn?: string;
  autorenew?: string;
  snap?: string;
  snapped?: string;
}

export interface FilterProps {
  autorenew: string;
  snap: string;
  snapped: string;
  registrar: string;
  daysUntilDeletion: number;
}

export const allowedRegistrars = ["hostedco5kmft9", "shekwan", "boobop"];

const ListPage: React.FC = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const action = queryParams.get("action");

  let defaultDeletingIn: number | undefined = undefined;
  let defaultAutorenew: string = "any";
  let defaultSnap: string = "any";
  let defaultSnapped: string = "any";

  switch (action) {
    case "intDomainsDeleting1":
      defaultDeletingIn = 1;
      break;
    case "intDomainsDeleting14":
      defaultDeletingIn = 14;
      break;
    case "intDomainsAutorenewOff":
      defaultAutorenew = "disabled";
      break;
    case "intDomainsGrab":
      defaultSnap = "yes";
      break;
    case "intDomainsGrabbed":
      defaultSnapped = "yes";
      break;
  }

  const [domains, setDomains] = useState<SnapperDomain[]>([]);
  const [page, setPage] = useState<number>(0);
  const [amount, setAmount] = useState<number>(10);
  const [filterTotal, setFilterTotal] = useState<number>(10);
  const [autorenew, setAutorenew] = useState<string>(defaultAutorenew);
  const [snap, setSnap] = useState<string>(defaultSnap);
  const [snapped, setSnapped] = useState<string>(defaultSnapped);
  const [registrar, setRegistrar] = useState<string>("");
  const [deletingIn, setDeletingIn] = useState<number | undefined>(
    defaultDeletingIn
  );
  const [selected, setSelected] = React.useState<string[]>([]);

  const [search, setSearch] = useState<string>("");
  const debouncedSearch = useDebounce(search, 800);

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDomainNames, setAddDomainNames] = useState<string[]>([]);
  const [addDomainError, setAddDomainError] = useState<string | null>(null);
  const [addDomainLoading, setAddDomainLoading] = useState(false);

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [deleteDomainError, setDeleteDomainError] = useState<string | null>(
    null
  );
  const [deleteDomainLoading, setDeleteDomainLoading] = useState(false);

  const [snackOpen, setSnackOpen] = React.useState(false);
  const [snackMessage, setSnackMessage] = React.useState("");

  const [order, setOrder] = React.useState<Order>("asc");
  const [orderBy, setOrderBy] =
    React.useState<keyof SnapperDomain>("strDomainName");

  const handleSnackClose = () => {
    setSnackOpen(false);
  };

  const handle_apply_filter = (filter_props: FilterProps) => {
    setAutorenew(filter_props.autorenew);
    setRegistrar(filter_props.registrar);
    setDeletingIn(filter_props.daysUntilDeletion);
    setSnap(filter_props.snap);
    setSnapped(filter_props.snapped);
  };

  const removeSelected = (itemToRemove: string) => {
    const new_list = selected.filter((item) => item !== itemToRemove);
    setSelected(new_list);
    handleDeleteValidate(new_list);
  };

  const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)+[a-zA-Z]{2,24}$/;

  const handleDomainValidate = () => {
    const invalidDomains = addDomainNames.filter(
      (domain) => !domainRegex.test(domain.trim())
    );

    if (invalidDomains.length > 0) {
      setAddDomainError(`Invalid domains: ${invalidDomains.join(", ")}`);
      return false;
    } else {
      setAddDomainError("");
      return true;
    }
  };

  const handleDeleteValidate = (list?: string[]) => {
    const process_list = list ? list : selected;
    const invalidDomains = process_list.filter((domainName) => {
      const domain = domains.find((d) => d.strDomainName === domainName);
      return domain && !allowedRegistrars.includes(domain.strRegistrar);
    });

    if (invalidDomains.length > 0) {
      setDeleteDomainError(
        `Does not belong to your organization: ${invalidDomains.join(", ")}`
      );
      return false;
    } else {
      setDeleteDomainError("");
      return true;
    }
  };

  const handle_add_domain_names = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setAddDomainNames(
      event.target.value.split("\n").filter((item) => item.length > 0)
    );
    setAddDomainError("");
  };

  const handle_search_change = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  let filters: SearchFilters = {
    search: debouncedSearch,
    limit: amount,
    startPoint: page * amount,
    autorenew,
    snap,
    snapped,
    order,
    sortBy: orderBy
  };

  if (registrar.length > 0) filters.strRegistrar = registrar;

  if (deletingIn) filters.deletingIn = `${deletingIn} days`;

  const handle_click_dialog_open = () => {
    setDialogOpen(true);
  };

  const handle_dialog_close = () => {
    setDialogOpen(false);
    setAddDomainNames([]);
  };

  const handle_delete_dialog_open = () => {
    setDeleteDialogOpen(true);
    handleDeleteValidate();
  };

  const handle_delete_dialog_close = () => {
    setDeleteDialogOpen(false);
    setDeleteDomainError("");
  };

  const downloadCSV = (data: string[], filename: string) => {
    const csv = data.map((item) => `"${item.replace(/"/g, '""')}"`).join("\n");
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    const url = URL.createObjectURL(blob);
    link.setAttribute("href", url);
    link.setAttribute("download", filename);
    link.click();
  };

  const exportSelectedItems = () => {
    if (selected.length === 0) {
      setSnackMessage("No items selected");
      setSnackOpen(true);
      return;
    }

    downloadCSV(selected, "selected-items.csv");
    setSnackMessage(`Successfully exported ${selected.length} items`);
    setSnackOpen(true);
  };

  const exportAllItems = async () => {
    let allItems: any[] = [];
    let currentPage = 1;

    setIsLoading(true);
    setSnackMessage(`Busy exporting ${filterTotal} items`);
    setSnackOpen(true);

    try {
      do {
        const exportFilters: SearchFilters = {
          search: debouncedSearch,
          limit: 500,
          startPoint: (currentPage - 1) * 500,
          autorenew,
          snap,
          snapped,
          order,
          sortBy: orderBy
        };
        const result = await fetchDomains(exportFilters)
          .then((data) => {
            return data.arrSnapperList.map(
              (item: SnapperDomain) => item.strDomainName
            );
          })
          .catch((err) => setError(err));
        allItems = [...allItems, ...result];
        currentPage++;
      } while ((currentPage - 1) * 500 <= filterTotal);

      downloadCSV(allItems, "all-items.csv");
      setIsLoading(false);
      setSnackMessage(`Successfully exported ${allItems.length} items`);
      setSnackOpen(true);
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    fetchDomains(filters)
      .then((data) => {
        setSelected([]);
        setDomains(data.arrSnapperList);
        setFilterTotal(data.intFilterTotal);
      })
      .catch((err) => setError(err))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    debouncedSearch,
    autorenew,
    registrar,
    deletingIn,
    page,
    amount,
    snap,
    snapped,
    order,
    orderBy
  ]);

  const set_autorenew = async (domain: string, autorenew: boolean) => {
    setIsLoading(true);
    try {
      const result = await autorenewToggle(domain, autorenew)
        .then((data) => data)
        .catch((err) => {
          setError(err);
          setIsLoading(false);
        });

      if (result) {
        if (result?.intReturnCode === 1) {
          await fetchDomains(filters)
            .then((data) => {
              setDomains(data.arrSnapperList);
              setSnackMessage(
                `Successfully ${autorenew ? "enabled" : "disabled"} autorenew`
              );
              setSnackOpen(true);
            })
            .catch((err) => setError(err))
            .finally(() => {
              setIsLoading(false);
              handle_dialog_close();
            });
        } else {
          setError("Error processing autorenew change: " + result?.strMessage);
          setIsLoading(false);
        }
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError("Error processing autorenew change: " + err.message);
      } else {
        setError("Unkown error while processing autorenew change.");
      }
    }
  };

  const handle_registry_delete = async () => {
    if (handleDeleteValidate()) {
      setDeleteDomainLoading(true);
      try {
        const results = await Promise.all(
          selected.map((name) =>
            deleteDomain(name)
              .then((data) => data)
              .catch((err) => {
                setDeleteDomainError(err);
                return null;
              })
          )
        );

        const hasSuccess = results.some(
          (res) => res && res.intReturnCode === 1
        );
        // const hasFailures = results.some((res) => res && res.intReturnCode === 0);

        if (hasSuccess) {
          await fetchDomains(filters)
            .then((data) => {
              setDomains(data.arrSnapperList);
            })
            .catch((err) => setDeleteDomainError(err))
            .finally(() => setDeleteDomainLoading(false));
        } else {
          setDeleteDomainLoading(false);
        }
      } catch (err) {
        setDeleteDomainLoading(false);
        if (err instanceof Error) {
          setDeleteDomainError("Error processing delete: " + err.message);
        } else {
          setDeleteDomainError("Unkown error while processing delete.");
        }
      }
    }
  };

  const set_grab = async (names: string[]) => {
    setIsLoading(true);
    try {
      const results = await Promise.all(
        names.map((name) =>
          grab(name)
            .then((data) => data)
            .catch((err) => {
              setError(err);
              return null;
            })
        )
      );

      const hasSuccess = results.some((res) => res && res.intReturnCode === 1);

      if (hasSuccess) {
        await fetchDomains(filters)
          .then((data) => {
            setDomains(data.arrSnapperList);
          })
          .catch((err) => setError(err))
          .finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError("Error processing grab: " + err.message);
      } else {
        setError("Unkown error while processing grab.");
      }
    }
  };

  const set_cancel_grab = async (names: string[]) => {
    setIsLoading(true);
    try {
      const results = await Promise.all(
        names.map((name) =>
          cancelGrab(name)
            .then((data) => data)
            .catch((err) => {
              setError(err);
              return null;
            })
        )
      );

      const hasSuccess = results.some((res) => res && res.intReturnCode === 1);

      if (hasSuccess) {
        await fetchDomains(filters)
          .then((data) => {
            setDomains(data.arrSnapperList);
          })
          .catch((err) => setError(err))
          .finally(() => setIsLoading(false));
      } else {
        setIsLoading(false);
      }
    } catch (err) {
      if (err instanceof Error) {
        setError("Error processing cancel grab: " + err.message);
      } else {
        setError("Unkown error while processing cancel grab.");
      }
    }
  };

  const handle_add_domains = async () => {
    if (handleDomainValidate()) {
      setAddDomainLoading(true);
      try {
        const result = await bulkGrab(addDomainNames)
          .then((data) => data)
          .catch((err) => {
            setError(err);
            setAddDomainLoading(false);
          });

        if (result && result.intReturnCode === 1) {
          await fetchDomains(filters)
            .then((data) => {
              setDomains(data.arrSnapperList);
              setSnackMessage("Successfully added domains");
              setSnackOpen(true);
            })
            .catch((err) => setError(err))
            .finally(() => {
              setAddDomainLoading(false);
              handle_dialog_close();
            });
        } else {
          setAddDomainLoading(false);
        }
      } catch (err) {
        if (err instanceof Error) {
          setError("Error processing cancel grab: " + err.message);
        } else {
          setError("Unkown error while processing cancel grab.");
        }
      }
    }
  };

  return (
    <Layout>
      <Snackbar
        open={snackOpen}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        autoHideDuration={6000}
        onClose={handleSnackClose}
        message={snackMessage}
      />
      <Box mt={2}>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={dialogOpen}
          onClose={handle_dialog_close}
        >
          <DialogTitle>Add New Domains</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handle_dialog_close}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <Close />
          </IconButton>
          <Divider />
          <DialogContent>
            {addDomainError ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {addDomainError}
              </Alert>
            ) : (
              <></>
            )}
            <DialogContentText>
              Domains can be added in bulk below each on a new line.
            </DialogContentText>

            <TextField
              id="add-new-domains"
              multiline
              fullWidth
              autoComplete="off"
              sx={{ mt: 2 }}
              rows={8}
              onChange={handle_add_domain_names}
            />
          </DialogContent>
          <DialogActions>
            {addDomainLoading ? (
              <Typography
                color="inherit"
                variant="subtitle1"
                component="div"
                mr={2}
                mb={3}
              >
                Loading...
              </Typography>
            ) : (
              <Button
                sx={{ mr: 2, mb: 2 }}
                variant="contained"
                onClick={handle_add_domains}
              >
                Add
              </Button>
            )}
          </DialogActions>
        </Dialog>
        <Dialog
          fullWidth
          maxWidth="sm"
          open={deleteDialogOpen && selected.length > 0}
          onClose={handle_delete_dialog_close}
        >
          <DialogTitle>Confirm deletion</DialogTitle>
          <IconButton
            aria-label="close"
            onClick={handle_delete_dialog_close}
            sx={(theme) => ({
              position: "absolute",
              right: 8,
              top: 8,
              color: theme.palette.grey[500]
            })}
          >
            <Close />
          </IconButton>
          <Divider />
          <DialogContent>
            {deleteDomainError ? (
              <Alert severity="error" sx={{ mb: 2 }}>
                {deleteDomainError}
              </Alert>
            ) : (
              <></>
            )}
            <DialogContentText>
              Are you sure you want to send registry delete commands for the
              below domains?
            </DialogContentText>
            <List dense={true} sx={{ maxHeight: "50vh", mt: "2" }}>
              {selected.map((domain) => (
                <ListItem
                  secondaryAction={
                    <IconButton edge="end" aria-label="delete">
                      <DeleteIcon onClick={() => removeSelected(domain)} />
                    </IconButton>
                  }
                >
                  <ListItemText primary={domain} />
                </ListItem>
              ))}
            </List>
          </DialogContent>
          <DialogActions>
            {deleteDomainLoading ? (
              <Typography
                color="inherit"
                variant="subtitle1"
                component="div"
                mr={2}
                mb={3}
              >
                Working...
              </Typography>
            ) : (
              <>
                <Button
                  sx={{ mr: 2, mb: 2 }}
                  variant="contained"
                  onClick={handle_delete_dialog_close}
                >
                  Cancel
                </Button>
                <Button
                  sx={{ mr: 2, mb: 2 }}
                  variant="contained"
                  color="error"
                  onClick={handle_registry_delete}
                >
                  Yes, delete
                </Button>
              </>
            )}
          </DialogActions>
        </Dialog>
        <Typography variant="h5" mb={4}>
          Snapper List
        </Typography>
        {error ? (
          <Alert sx={{ m: 2 }} severity="error" onClose={() => setError(null)}>
            {error}
          </Alert>
        ) : (
          <></>
        )}
        <Grid2 container sx={{ justifyContent: "space-between" }}>
          <Grid2 width={360}>
            <TextField
              style={{ marginLeft: 16 }}
              fullWidth
              autoComplete="off"
              id="search-domains"
              label="Search Domains"
              variant="outlined"
              onChange={handle_search_change}
              value={search}
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      {search && (
                        <IconButton onClick={() => setSearch("")}>
                          <Clear />
                        </IconButton>
                      )}
                    </InputAdornment>
                  )
                }
              }}
            />
          </Grid2>
          <Grid2
            sx={{
              alignContent: "center"
              // justifyItems: "end"
            }}
          >
            <>
              <Button
                onClick={exportAllItems}
                variant="outlined"
                style={{ marginRight: 16 }}
                endIcon={<FileDownload />}
              >
                Export Filtered Results
              </Button>
              <Button
                onClick={handle_click_dialog_open}
                variant="contained"
                style={{ marginRight: 16 }}
                endIcon={<Add />}
              >
                Add Domains
              </Button>
            </>
          </Grid2>
        </Grid2>
        <DataTable
          data={domains}
          set_cancel_grab={set_cancel_grab}
          set_grab={set_grab}
          loading={isLoading}
          handle_apply_filter={handle_apply_filter}
          setPage={setPage}
          page={page}
          setAmount={setAmount}
          amount={amount}
          filterTotal={filterTotal}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          selected={selected}
          setSelected={setSelected}
          exportSelectedItems={exportSelectedItems}
          set_autorenew={set_autorenew}
          delete_domains={handle_delete_dialog_open}
        />
      </Box>
    </Layout>
  );
};

export default ListPage;
