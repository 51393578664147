/**
 * Splits a domain name into its Second-Level Domain (SLD) and Top-Level Domain (TLD).
 *
 * @param {string} domain - The domain name to split (e.g., "example.co.za" or "test.com").
 * @returns {{ sld: string, tld: string }} An object containing the SLD (second-level domain) and TLD (top-level domain).
 * @throws {Error} If the domain is invalid or doesn't contain both an SLD and TLD.
 *
 * @example
 * const result = splitDomain("test.co.za");
 * // result.sld = "test"
 * // result.tld = "co.za"
 */
export function splitDomain(domain: string): { sld: string; tld: string } {
  // Split the domain by '.'
  const parts = domain.split(".");

  if (parts.length < 2) {
    throw new Error("Invalid domain");
  }

  // The SLD is the first part
  const sld = parts[0];

  // The TLD is the remaining part joined with '.'
  const tld = parts.slice(1).join(".");

  return { sld, tld };
}
